import { Fragment, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { MinusIcon, OverlayTriggerTooltip, Pill, PlusIcon } from '@utilities';
import { getPromptPillTooltip, getPromptPillValue } from '../../../../utilities/helpers';

import { getPromptIdsForBehavior, getInitialVisibleCount } from './utilities/helpers';

import styles from './_index.module.scss';

const GroupMadlib = ({
  activePrompt,
  group,
  isReadOnly,
  onClearAnswer,
  onSetActivePrompt,
  surveyType,
  updateVisiblePrompts,
}) => {
  const { prompts, madlib } = surveyType;
  const { id, promptAnswers } = group;

  const regex = /{[^}]*}|[^{}]+/g;
  const behaviors = madlib.split('||').map((behavior) => behavior.match(regex) || []);
  let promptIndex = 0;

  const [visibleCount, setVisibleCount] = useState(
    getInitialVisibleCount(behaviors, prompts, promptAnswers)
  );

  const initializeVisiblePrompts = useCallback(() => {
    if (isReadOnly) return;

    updateVisiblePrompts(id, (prevVisible) => {
      const initialPromptIds = new Set(prevVisible);

      behaviors.slice(0, visibleCount).forEach((behaviorParts) => {
        getPromptIdsForBehavior(behaviorParts, prompts).forEach((id) => initialPromptIds.add(id));
      });

      return initialPromptIds;
    });
  }, [behaviors, id, isReadOnly, prompts, updateVisiblePrompts, visibleCount]);

  useEffect(() => {
    initializeVisiblePrompts();
  }, [initializeVisiblePrompts]);

  const handleRemoveRow = (behaviorIndex) => {
    const promptIdsToRemove = getPromptIdsForBehavior(behaviors[behaviorIndex], prompts);

    promptIdsToRemove.forEach(onClearAnswer);

    updateVisiblePrompts(id, (prevVisible) => {
      const updatedVisible = new Set(prevVisible);
      promptIdsToRemove.forEach((id) => updatedVisible.delete(id));
      return updatedVisible;
    });

    setVisibleCount((prev) => Math.max(1, prev - 1));
  };

  return (
    <div className={classNames(styles['group-madlib'], { [styles['is-complete']]: isReadOnly })}>
      {behaviors.slice(0, visibleCount).map((behaviorParts, behaviorIndex) => {
        const isLastVisibleRow = behaviorIndex === visibleCount - 1;

        return (
          <div key={`behavior-${behaviorIndex}`}>
            <div className={styles['behavior-block']}>
              {behaviorIndex !== 0 && (
                <OverlayTriggerTooltip
                  content={!isLastVisibleRow || isReadOnly ? '' : 'Remove Row'}
                >
                  <button
                    className={classNames(styles['button-icon'], styles['minus'])}
                    data-testid="behavior-button-minus-icon"
                    onClick={() => handleRemoveRow(behaviorIndex)}
                    style={{ visibility: isLastVisibleRow && !isReadOnly ? 'visible' : 'hidden' }}
                  >
                    <MinusIcon />
                  </button>
                </OverlayTriggerTooltip>
              )}
              <div>
                {behaviorParts.map((part, partIndex) => {
                  if (!part.startsWith('{')) {
                    return (
                      <Fragment key={`madlib-part-${behaviorIndex}-${partIndex}`}>{part}</Fragment>
                    );
                  }

                  const prompt = prompts[promptIndex++];
                  const currentPromptAnswers = promptAnswers.filter(
                    ({ _delete, promptId }) => !_delete && promptId === prompt.id
                  );
                  const tooltip = getPromptPillTooltip({ prompt, promptAnswers });
                  const value = getPromptPillValue({ prompt, promptAnswers });

                  return (
                    <Fragment key={`madlib-pill-${behaviorIndex}-${partIndex}`}>
                      <OverlayTriggerTooltip content={tooltip} isFullWidth>
                        <Pill
                          data-testid={`group-madlib-pill-${prompt.id}`}
                          isActive={prompt === activePrompt}
                          isCompleted={isReadOnly}
                          isModified={currentPromptAnswers.length > 0}
                          isRequired={prompt.isRequired}
                          onClick={() => onSetActivePrompt(prompt)}
                          onClose={() => onClearAnswer(prompt.id)}
                          value={value}
                        />
                      </OverlayTriggerTooltip>
                    </Fragment>
                  );
                })}
              </div>
            </div>
            {visibleCount < behaviors.length &&
              visibleCount <= behaviorIndex + 1 &&
              !isReadOnly && (
                <OverlayTriggerTooltip content="Add Row">
                  <button
                    className={classNames(styles['button-icon'], styles['plus'])}
                    onClick={() => setVisibleCount((prev) => prev + 1)}
                    data-testid="behavior-button-plus-icon"
                  >
                    <PlusIcon />
                  </button>
                </OverlayTriggerTooltip>
              )}
          </div>
        );
      })}
    </div>
  );
};

GroupMadlib.propTypes = {
  activePrompt: PropTypes.object,
  group: PropTypes.object.isRequired,
  isReadOnly: PropTypes.bool,
  onClearAnswer: PropTypes.func,
  onSetActivePrompt: PropTypes.func,
  surveyType: PropTypes.object.isRequired,
  updateVisiblePrompts: PropTypes.func,
};

export default GroupMadlib;
