import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useGate } from 'statsig-react';

import { GATES } from '@constants';

import ModuleListItems from './components/ModuleListItems';
import { categorizeRoutes, filterRoutes } from './utilities/helpers';

import styles from './_index.module.scss';

const ModuleList = ({ routes, type, show_favorites: showFavorites }) => {
  const { value: canStandardSurvey } = useGate(GATES.STANDARD_SURVEY);

  const {
    allRoutes,
    favoriteRoutes,
    standardSurveyRoutes = [],
    specialtySurveyRoutes = [],
  } = canStandardSurvey
    ? categorizeRoutes(routes, showFavorites)
    : filterRoutes(routes, showFavorites);

  return (
    <div>
      {type === 'survey' && canStandardSurvey && (
        <>
          {standardSurveyRoutes.length > 0 && (
            <div className={styles['module-list']}>
              <h4>Standard</h4>
              <ModuleListItems routes={standardSurveyRoutes} type={type} />
            </div>
          )}
          {specialtySurveyRoutes.length > 0 && (
            <div className={classNames(styles['module-list'], styles['specialty'])}>
              <h4>Specialty Surveys</h4>
              <ModuleListItems routes={specialtySurveyRoutes} type={type} />
            </div>
          )}
        </>
      )}

      {(type === 'report' || (type === 'survey' && !canStandardSurvey)) && (
        <div>
          {showFavorites && favoriteRoutes.length > 0 && (
            <div className={classNames(styles['module-list'], styles['favorite'])}>
              <h4>Favorites</h4>
              <ModuleListItems routes={favoriteRoutes} type={type} />
            </div>
          )}
          <ModuleListItems routes={allRoutes} type={type} />
        </div>
      )}
    </div>
  );
};

ModuleList.propTypes = {
  routes: PropTypes.array.isRequired,
  show_favorites: PropTypes.bool,
  type: PropTypes.oneOf(['report', 'survey']).isRequired,
};

export default ModuleList;
