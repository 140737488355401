import { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
  Checkbox,
  handlePageClick,
  LoaderSkeleton,
  OverlayTriggerTooltip,
  Pagination,
  Pill,
  PinIcon,
  Radio,
  toast,
  usePaginatedItems,
} from '@utilities';

import { createPinnedAnswer, deletePinnedAnswer } from '@api/prompts';

import Inputs from './components/Inputs';
import Metrics from './components/Metrics';

import { getOptionLabel } from './utilities/helpers';

import styles from './_index.module.scss';

const Options = ({
  activeAnswers,
  activePrompt,
  documentId,
  hasActivePromptLimit,
  isLoading,
  isSurvey,
  max,
  onSelectAnswer,
  onSetAnswerInput,
  onSetAnswerMetric,
  operators,
  options,
  pinnedAnswers,
  setPinnedAnswers,
}) => {
  const [isPinning, setIsPinning] = useState(false);

  const { currentItems, itemsPerPage, pageCount, setOffset } = usePaginatedItems({
    items: options,
  });
  const onPageChange = (event) => {
    handlePageClick({ event, items: options, setOffset });
  };

  const isSelectAllChecked = currentItems?.every((option) =>
    activeAnswers?.some((activeAnswer) => activeAnswer?.id === option?.id)
  );

  const handleOnChangeSelectAll = (options) => {
    onSelectAnswer(options, true, isSelectAllChecked ? false : true);
  };

  const handlePin = async ({ isPinned, option }) => {
    if (isPinned || isPinning) {
      return;
    }
    setIsPinning(true);

    try {
      const response = await createPinnedAnswer({
        documentId,
        option,
        promptId: activePrompt?.id,
      });
      setPinnedAnswers((prevPinnedAnswers) => {
        const updatedPinnedAnswers = { ...prevPinnedAnswers };
        updatedPinnedAnswers[activePrompt.id] = [
          ...prevPinnedAnswers[activePrompt.id],
          response?.data,
        ];
        return updatedPinnedAnswers;
      });
    } catch (error) {
      if (error.response.status === 400 || error.response.status === 409) {
        toast(
          'An error occurred while pinning this answer. Please try again or refresh the page to see your latest pinned answers.',
          { status: 'error' }
        );
      }
      console.error('Failed to pin:', error);
    } finally {
      setIsPinning(false);
    }
  };

  const handleUnpin = async ({ pinnedAnswerId }) => {
    try {
      await deletePinnedAnswer({ pinnedAnswerId });

      setPinnedAnswers((prevPinnedAnswers) => {
        const updatedPinnedAnswers = { ...prevPinnedAnswers };
        updatedPinnedAnswers[activePrompt.id] = updatedPinnedAnswers[activePrompt.id].filter(
          (pinnedAnswer) => pinnedAnswer.pinnedAnswerId !== pinnedAnswerId
        );
        return updatedPinnedAnswers;
      });
    } catch (error) {
      console.error('Failed to remove pin:', error);
    }
  };

  const promptPinnedAnswers = pinnedAnswers?.[activePrompt?.id];
  const isMaxPinnedReached = promptPinnedAnswers?.length >= 10;

  if (isLoading)
    return (
      <LoaderSkeleton>
        <rect x="0" y="0" rx="2" ry="2" width="200" height="15" />
        <rect x="0" y="25" rx="2" ry="2" width="175" height="15" />
        <rect x="0" y="50" rx="2" ry="2" width="225" height="15" />
        <rect x="0" y="75" rx="2" ry="2" width="200" height="15" />
        <rect x="0" y="100" rx="2" ry="2" width="225" height="15" />
      </LoaderSkeleton>
    );

  if (currentItems === null || currentItems?.length === 0) {
    return <div>No results found.</div>;
  }

  return (
    <div className={styles['options']}>
      <div>
        <div className={styles['options-select-all']}>
          {!isSurvey && max === null && (
            <Checkbox
              data-testid="select-all-checkbox"
              isChecked={isSelectAllChecked}
              label={isSelectAllChecked ? 'Unselect All on Page' : 'Select All on Page'}
              name={activePrompt?.id}
              onChange={handleOnChangeSelectAll}
              value={currentItems}
            />
          )}
        </div>
        <ul className={styles['options-list']}>
          {currentItems?.map((option, index) => {
            const optionAnswers = activeAnswers
              ? activeAnswers.filter((activeAnswer) => activeAnswer.id === option.id)
              : [];

            const pinnedAnswer = promptPinnedAnswers?.find(
              (pinnedAnswer) => pinnedAnswer.id === option.id
            );

            const isPinned = pinnedAnswer ? true : false;

            const isChecked = optionAnswers?.length === 1;
            const optionAnswer = isChecked ? optionAnswers[0] : undefined;
            const value = optionAnswer || option;

            return (
              <li key={`option-${index}`}>
                <>
                  {max === 1 ? (
                    <Radio
                      isChecked={isChecked}
                      label={getOptionLabel(option)}
                      name={activePrompt?.id}
                      onChange={onSelectAnswer}
                      value={value}
                    />
                  ) : (
                    <>
                      <Checkbox
                        isChecked={isChecked}
                        isDisabled={!isChecked && hasActivePromptLimit}
                        label={getOptionLabel(option)}
                        name={activePrompt?.id}
                        onChange={onSelectAnswer}
                        value={value}
                      />
                    </>
                  )}
                  {!isSurvey && activePrompt?.isPinnable && (
                    <div
                      className={classNames(styles['option-pin'], {
                        [styles['is-pinned']]: isPinned,
                      })}
                    >
                      <OverlayTriggerTooltip
                        content={
                          isMaxPinnedReached && !isPinned
                            ? 'You have pinned 10 answers for this prompt. Unpin one to add this answer.'
                            : null
                        }
                      >
                        <Pill
                          icon={<PinIcon />}
                          isDisabled={isMaxPinnedReached && !isPinned}
                          isModified={isPinned}
                          onClick={() => handlePin({ isPinned, option })}
                          onClose={() =>
                            handleUnpin({
                              pinnedAnswerId: pinnedAnswer?.pinnedAnswerId,
                            })
                          }
                        />
                      </OverlayTriggerTooltip>
                    </div>
                  )}
                  {isChecked && option?.inputs && (
                    <Inputs
                      answerInputs={optionAnswer?.inputs}
                      inputs={option?.inputs}
                      onSetAnswerInput={onSetAnswerInput}
                      optionId={option?.id}
                    />
                  )}
                  {isChecked && option?.operatorInfo && (
                    <Metrics
                      metric={value}
                      onSetAnswerMetric={onSetAnswerMetric}
                      operators={operators}
                    />
                  )}
                </>
              </li>
            );
          })}
        </ul>
        <Pagination
          onPageChange={onPageChange}
          pageCount={pageCount}
          show={options?.length > itemsPerPage}
        />
      </div>
    </div>
  );
};

Options.propTypes = {
  activeAnswers: PropTypes.array,
  activePrompt: PropTypes.object,
  documentId: PropTypes.string,
  hasActivePromptLimit: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSurvey: PropTypes.bool,
  max: PropTypes.number,
  onSelectAnswer: PropTypes.func,
  onSetAnswerInput: PropTypes.func,
  onSetAnswerMetric: PropTypes.func,
  operators: PropTypes.array,
  options: PropTypes.array,
  pinnedAnswers: PropTypes.object,
  setPinnedAnswers: PropTypes.func,
};

export default Options;
