// import { classNames } from 'classnames';
import { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { QUESTION_TYPES } from '@api/instant_survey';
import { getQuestionIcon } from '@helpers';
import {
  ActionOverlayPopover,
  DragDropV2Icon,
  ExclusiveIcon,
  Layout,
  PinIcon,
  TrashIcon,
} from '@utilities';

import styles from './_index.module.scss';

const Answer = ({ answer, children, isDraggable, onAnchor, onDelete, onMakeExclusive, type }) => {
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const optionsDropdown = useRef(null);

  return (
    <div className={styles['answer']}>
      <div className={styles['answer-icons']}>
        {isDraggable && <DragDropV2Icon />}
        {getQuestionIcon({ type })}
      </div>

      <Layout.Fill className={styles['answer-input-wrapper']}>{children}</Layout.Fill>

      {((answer.isExclusive && type === QUESTION_TYPES.CHECKBOX.value) || answer.isAnchored) && (
        <Layout.Flex className={styles['answer-properties']} gap="small">
          {answer.isExclusive && type === QUESTION_TYPES.CHECKBOX.value && <ExclusiveIcon />}
          {answer.isAnchored && <PinIcon />}
          <div className={styles['answer-pipe']} />
        </Layout.Flex>
      )}

      <div className={styles['answer-controls']}>
        <ActionOverlayPopover
          hasArrow={false}
          isActive={isDropdownActive}
          onClick={() => setIsDropdownActive(!isDropdownActive)}
          onHide={() => setIsDropdownActive(false)}
          target={optionsDropdown.current}
        >
          <ul>
            {!answer.isNoneOfTheAbove && type === QUESTION_TYPES.CHECKBOX.value && (
              <li>
                <button
                  aria-label={
                    answer.isExclusive ? 'Make answer exclusive' : 'Make answer unexclusive'
                  }
                  className="answer-option-button"
                  onClick={() => {
                    onMakeExclusive();
                    setIsDropdownActive(false);
                  }}
                >
                  <ExclusiveIcon />
                  <span>Make {answer.isExclusive ? 'Unexclusive' : 'Exclusive'}</span>
                </button>
              </li>
            )}
            {!answer.isNoneOfTheAbove && !answer.isOther && (
              <>
                <li>
                  <button
                    aria-label={answer.isAnchored ? 'Unpin answer' : 'Pin answer'}
                    className="answer-option-button"
                    onClick={() => {
                      onAnchor();
                      setIsDropdownActive(false);
                    }}
                  >
                    <PinIcon />
                    <span>{answer.isAnchored ? 'Unpin' : 'Pin'} Position</span>
                  </button>
                </li>
                <hr />
              </>
            )}
            <li>
              <button
                aria-label="Delete answer"
                className="answer-option-button"
                onClick={() => {
                  onDelete();
                  setIsDropdownActive(false);
                }}
              >
                <TrashIcon />
                <span>Delete</span>
              </button>
            </li>
          </ul>
        </ActionOverlayPopover>
      </div>
    </div>
  );
};

Answer.defaultProps = {
  onDelete: () => {},
};

Answer.propTypes = {
  answer: PropTypes.object,
  children: PropTypes.node,
  isDraggable: PropTypes.bool,
  onAnchor: PropTypes.func,
  onDelete: PropTypes.func,
  onMakeExclusive: PropTypes.func,
  type: PropTypes.string.isRequired,
};

export default Answer;
