export const ASK_WHY_QUOTA_SIZE = 100;
export const ASK_WHY_SURVEY_TYPE_ID = 'ask-why-v3-instant-survey-type';

export const AUTH_HEADER = 'insights-authorization';

export const AVAILABLE_PANELISTS_CAP = 10000;

export const DEFAULT_GROUP_NAME = 'Panelist Group';
export const DEFAULT_RESPONSE_COUNT = 200;

export const ERROR_SAVE_DRAFT = 'There was an error while saving changes to your survey.';

export const FIELDS_USING_ID = ['custom group', 'people group', 'purchased'];

export const FOLDER_ID_CUSTOM = 3;
export const FOLDER_ID_RECOMMENDED = 2;

export const MAINTENANCE_MESSAGE = 'Instant Survey currently down for maintenance.';

export const MODEL_TYPES = {
  QUESTION_FOLDER: 'QuestionFolder',
  QUESTION: 'Question',
};

export const PLACEHOLDER_IMAGE = 'https://www.numerator.com/wp-content/uploads/2023/09/Logo.svg';
export const QUESTION_LOGIC_OPERATORS = {
  ALL: {
    text: 'All of the Following',
    value: 'ALL',
  },
  ANY: {
    text: 'Any of the Following',
    value: 'ANY',
  },
  NOT: {
    text: 'Not Any of the Following',
    value: 'NOT',
  },
};
export const QUESTIONS_PER_PAGE = 15;
export const QUESTION_RANK_MAX_OPTIONS = 15;
export const QUESTION_TYPES = {
  CHECKBOX: { value: 'CHECKBOX', label: 'Multiple Select (Check Box)', name: 'Multiple Select' },
  ESSAY: { value: 'ESSAY', label: 'Open End (Essay)', name: 'Open End' },
  RADIO: { value: 'RADIO', label: 'Single Select (Radio Button)', name: 'Single Select' },
  'RANK-DRAGDROP': { value: 'RANK-DRAGDROP', label: 'Rank', name: 'Rank' },
};

export const RESPONSE_COUNT_INCREMENT = 50;
export const RESPONSE_COUNT_MINIMUM = 50;

export const SURVEY_DESCRIPTION_DEFAULT = 'Standard Survey (6-15 Questions)';
export const SURVEY_DESCRIPTION_LONG = 'Long Survey (16-20 Questions)';
export const SURVEY_DESCRIPTION_SHORT = 'Short Survey (1-5 Questions)';

export const SURVEY_GROUP_VALIDATION_STATUS = {
  ERROR: 'ERROR',
  NOT_ENOUGH_SAMPLE: 'NOT_ENOUGH_SAMPLE',
  NOT_VALIDATED: 'NOT_VALIDATED', // default
  VALIDATED: 'VALIDATED',
  VALIDATING: 'VALIDATING',
};

export const SURVEY_GROUP_VALIDATION_ERROR_HEADER = 'Complex Sample: Manual Review Required';
export const SURVEY_GROUP_VALIDATION_ERROR_TEXT =
  'The Numerator Research Team will review this quota group for available sample before activating the survey for panelists. If there is a risk of not meeting your desired # of completes, we will contact you.';

export const SURVEY_LENGTH_LONG = 16;
export const SURVEY_LENGTH_SHORT = 5;

export const SURVEY_HELP_URL = 'https://help.insights.numerator.com/en/collections/3625626-surveys';
export const SURVEY_QUESTIONNAIRE_PRICING = [
  {
    id: 1,
    number_of_questions: 0,
    credits_cost_questionnaire: '1.00',
  },
  {
    id: 2,
    number_of_questions: 1,
    credits_cost_questionnaire: '1.00',
  },
  {
    id: 3,
    number_of_questions: 2,
    credits_cost_questionnaire: '1.00',
  },
  {
    id: 4,
    number_of_questions: 3,
    credits_cost_questionnaire: '1.00',
  },
  {
    id: 5,
    number_of_questions: 4,
    credits_cost_questionnaire: '1.00',
  },
  {
    id: 6,
    number_of_questions: 5,
    credits_cost_questionnaire: '1.00',
  },
  {
    id: 7,
    number_of_questions: 6,
    credits_cost_questionnaire: '2.00',
  },
  {
    id: 8,
    number_of_questions: 7,
    credits_cost_questionnaire: '2.00',
  },
  {
    id: 9,
    number_of_questions: 8,
    credits_cost_questionnaire: '2.00',
  },
  {
    id: 10,
    number_of_questions: 9,
    credits_cost_questionnaire: '2.00',
  },
  {
    id: 11,
    number_of_questions: 10,
    credits_cost_questionnaire: '2.00',
  },
  {
    id: 12,
    number_of_questions: 11,
    credits_cost_questionnaire: '2.00',
  },
  {
    id: 13,
    number_of_questions: 12,
    credits_cost_questionnaire: '2.00',
  },
  {
    id: 14,
    number_of_questions: 13,
    credits_cost_questionnaire: '2.00',
  },
  {
    id: 15,
    number_of_questions: 14,
    credits_cost_questionnaire: '2.00',
  },
  {
    id: 16,
    number_of_questions: 15,
    credits_cost_questionnaire: '2.00',
  },
  {
    id: 17,
    number_of_questions: 16,
    credits_cost_questionnaire: '3.00',
  },
  {
    id: 18,
    number_of_questions: 17,
    credits_cost_questionnaire: '3.00',
  },
  {
    id: 19,
    number_of_questions: 18,
    credits_cost_questionnaire: '3.00',
  },
  {
    id: 20,
    number_of_questions: 19,
    credits_cost_questionnaire: '3.00',
  },
  {
    id: 21,
    number_of_questions: 20,
    credits_cost_questionnaire: '3.00',
  },
];

export const SURVEY_VALIDATION_ERROR_HEADER = 'Manual Review Required';
export const SURVEY_VALIDATION_ERROR_TEXT =
  'The Numerator Research Team will review this survey for available sample before activating it for panelists. If there is a risk of not meeting your desired # of completes, we will contact you.';

export const TEMPLATES_PER_PAGE = 5;
