import { nanoid } from 'nanoid';

import { FOLDER_ID_CUSTOM, QUESTION_TYPES } from '@api/instant_survey';

/**
 * Strips ids for questions and answers when editing a recommended question or duplicating an existing question
 * @param {Object} options
 * @param {Boolean} [options.isDuplicate]
 * @param {Object} options.question
 */
export const prepExistingQuestion = ({ availableQuestionTypes, isDuplicate, question }) => {
  const isCreating = isDuplicate || question.folder !== FOLDER_ID_CUSTOM;
  const answers = question.answers.map((answer) => ({
    ...answer,
    id: isCreating ? nanoid() : answer.id,
  }));

  return {
    ...question,
    answers: answers.filter(({ isNoneOfTheAbove, isOther }) => !isNoneOfTheAbove && !isOther),
    id: isCreating ? undefined : question.id,
    noneAnswer: answers.find(({ isNoneOfTheAbove }) => isNoneOfTheAbove) || null,
    otherAnswer: answers.find(({ isOther }) => isOther) || null,
    type:
      availableQuestionTypes.find(({ value }) => value === question.type) || QUESTION_TYPES.ESSAY,
  };
};
