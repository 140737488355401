import { ReactComponent as ConceptScreening } from '../assets/concept-screening.svg';
import { ReactComponent as GenericPlaceholder } from '../assets/generic-placeholder.svg';
import { ReactComponent as LapsingBrand } from '../assets/lapsing-brand.svg';
import { ReactComponent as Leakage } from '../assets/leakage.svg';
import { ReactComponent as NewItemLaunch } from '../assets/new-item-launch.svg';
import { ReactComponent as Promo } from '../assets/promo.svg';
import { ReactComponent as PurchaseJourney } from '../assets/purchase-journey.svg';
import { ReactComponent as QuickPulse } from '../assets/quick-pulse.svg';

/**
 * gets survey icon based on id
 * @param  {String} id
 * @return {Node}   react component
 */
export const getSurveyIcon = (id) => {
  switch (id) {
    case 'concept-screening-instant-survey-type':
    case 'concept-screening-v2-instant-survey-type':
    case 'concept-screening-v3-instant-survey-type':
      return <ConceptScreening />;
    case 'lapsing-brand-instant-survey-type':
    case 'lapsing-brand-v2-instant-survey-type':
    case 'lapsing-brand-v3-instant-survey-type':
      return <LapsingBrand />;
    case 'leakage-instant-survey-type':
    case 'leakage-v2-instant-survey-type':
    case 'leakage-v3-instant-survey-type':
      return <Leakage />;
    case 'new-item-launch-instant-survey-type':
      return <NewItemLaunch />;
    case 'promo-instant-survey-type':
    case 'buyer-instant-survey-type':
    case 'buyer-v2-instant-survey-type':
    case 'buyer-v3-instant-survey-type':
    case 'standard-v3-instant-survey-type':
      return <Promo />;
    case 'purchase-journey-instant-survey-type':
    case 'purchase-journey-v2-instant-survey-type':
    case 'purchase-journey-v3-instant-survey-type':
      return <PurchaseJourney />;
    case 'quick-pulse-instant-survey-type':
    case 'quick-pulse-v2-instant-survey-type':
    case 'quick-pulse-v3-instant-survey-type':
      return <QuickPulse />;
    default:
      return <GenericPlaceholder />;
  }
};
