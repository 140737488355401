/**
 * Extracts prompt keys from behavior text.
 * @param {string[]} behaviorParts
 * @returns {string[]}
 */
const extractPromptKeys = (behaviorParts) =>
  behaviorParts.filter((part) => part.startsWith('{')).map((part) => part.slice(1, -1));

/**
 * Finds a prompt object by its key.
 * @param {string} promptKey
 * @param {Array} prompts
 * @returns {Object|undefined}
 */
const getPromptFromKey = (promptKey, prompts) => prompts.find((p) => p.promptKey === promptKey);

/**
 * Retrieves a set of unique prompt IDs for a behavior.
 * @param {string[]} behaviorParts
 * @param {Array} prompts
 * @returns {Set<string>}
 */
export const getPromptIdsForBehavior = (behaviorParts, prompts) =>
  new Set(
    extractPromptKeys(behaviorParts)
      .map((key) => getPromptFromKey(key, prompts))
      .filter(Boolean)
      .map((prompt) => prompt.id)
  );

/**
 * Calculates the initial number of visible behaviors.
 * @param {Array} behaviors
 * @param {Array} prompts
 * @param {Array} promptAnswers
 * @returns {number}
 */
export const getInitialVisibleCount = (behaviors, prompts, promptAnswers) => {
  const answeredBehaviors = behaviors.filter((behaviorParts) =>
    extractPromptKeys(behaviorParts).some((promptKey) => {
      const prompt = getPromptFromKey(promptKey, prompts);
      return (
        prompt &&
        promptAnswers.some(({ promptId, values }) => prompt.id === promptId && values.length > 0)
      );
    })
  ).length;

  return Math.min(behaviors.length, Math.max(1, answeredBehaviors));
};
