import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './_index.module.scss';

const ErrorText = ({ as: Component = 'p', children, className, text, ...props }) => {
  return (
    <Component className={classNames(styles['error-text'], className)} {...props}>
      {children || text}
    </Component>
  );
};

ErrorText.propTypes = {
  as: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  text: PropTypes.string,
};

export default ErrorText;
