import PropTypes from 'prop-types';

import { isVerifiedVoices } from '@helpers';
import { ExternalLinkIcon, OverlayPopover } from '@utilities';

const HelpDropdown = ({ isHelpDropdownActive, setActiveDropdown, target }) => {
  if (!isHelpDropdownActive) return null;

  return (
    <OverlayPopover
      data-testid="help-dropdown"
      header="Help"
      isActive={isHelpDropdownActive}
      onHide={() => setActiveDropdown('')}
      target={target}
    >
      <ul>
        <li>
          <a
            href={
              isVerifiedVoices()
                ? 'https://help.verifiedvoices.insights.numerator.com/en'
                : 'http://help.insights.numerator.com/en/'
            }
            onClick={() => setActiveDropdown('')}
            rel="noreferrer"
            target="_blank"
          >
            <ExternalLinkIcon />
            <span>Help Center</span>
          </a>
        </li>
        <li>
          <a
            href="https://learn.numerator.com"
            onClick={() => setActiveDropdown('')}
            rel="noreferrer"
            target="_blank"
          >
            <ExternalLinkIcon />
            <span>Numerator University</span>
          </a>
        </li>
        <li>
          <a
            href="https://learn.numerator.com/pages/22/live-training"
            onClick={() => setActiveDropdown('')}
            rel="noreferrer"
            target="_blank"
          >
            <ExternalLinkIcon />
            <span>Live Training</span>
          </a>
        </li>
      </ul>
    </OverlayPopover>
  );
};

HelpDropdown.propTypes = {
  isHelpDropdownActive: PropTypes.bool,
  setActiveDropdown: PropTypes.func,
  target: PropTypes.object,
};

export default HelpDropdown;
