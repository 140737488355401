import { useState } from 'react';
import PropTypes from 'prop-types';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { nanoid } from 'nanoid';
import { useGate } from 'statsig-react';

import {
  createImageObject,
  createQuestion,
  FOLDER_ID_CUSTOM,
  QUESTION_RANK_MAX_OPTIONS,
  QUESTION_TYPES,
  updateQuestion,
  uploadImageToS3,
} from '@api/instant_survey';
import { GATES } from '@constants';
import {
  Announcement,
  Button,
  ButtonText,
  Checkbox,
  ErrorText,
  Fieldset,
  ModalV2 as Modal,
  PlusIcon,
  RichTextInput,
  Select,
  TextArea,
} from '@utilities';

import Answer from './components/Answer';

import {
  availableQuestionVariables,
  noneOfTheAbove,
  otherAlternates,
  otherPleaseSpecify,
} from './utilities/constants';
import { prepExistingQuestion } from './utilities/helpers';

import styles from './_index.module.scss';

const ANSWER_MAX_LENGTH = 150;
const QUESTION_MAX_LENGTH = 1000;

const ANSWER_LENGTH_ERROR = `Answer text exceeds the max-length of ${ANSWER_MAX_LENGTH}. Trim or remove it to continue.`;
const QUESTION_LENGTH_ERROR = `Question text length is too long. Trim it to ${QUESTION_MAX_LENGTH} characters or less to continue.`;

const ModalInstantSurveyQuestion = ({
  isDuplicate,
  onClose,
  onCreate,
  onUpdate,
  originalQuestion,
  surveyType,
}) => {
  const [deletedAnswers, setDeletedAnswers] = useState([]);
  const [errorSubmitting, setErrorSubmitting] = useState(null);
  const [hasImageError, setHasImageError] = useState({ answers: [] });
  const [isImageLoading, setIsImageLoading] = useState({ answers: [] });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { value: canQuestionTypeRank } = useGate(GATES.QUESTION_TYPE_RANK);
  const availableQuestionTypes = [
    QUESTION_TYPES.CHECKBOX,
    QUESTION_TYPES.RADIO,
    QUESTION_TYPES.ESSAY,
    ...(canQuestionTypeRank ? [QUESTION_TYPES['RANK-DRAGDROP']] : []),
  ];
  const [question, setQuestion] = useState(
    originalQuestion
      ? prepExistingQuestion({ availableQuestionTypes, isDuplicate, question: originalQuestion })
      : {
          answers: [
            { id: nanoid(), text: '' },
            { id: nanoid(), text: '' },
            { id: nanoid(), text: '' },
          ],
          image: null,
          noneAnswer: null,
          otherAnswer: null,
          randomizeOptions: false,
          text: '',
          type: QUESTION_TYPES.CHECKBOX,
        }
  );

  const isEditing = originalQuestion && !isDuplicate;
  const isRankType = question.type.value === QUESTION_TYPES['RANK-DRAGDROP'].value;

  const canAddNone = !isRankType && !question.noneAnswer;
  const canAddOther = !isRankType && !question.otherAnswer;

  const addAnswer = () => {
    setQuestion({ ...question, answers: [...question.answers, { id: nanoid(), text: '' }] });
  };

  const addNoneAnswer = () => {
    setQuestion((question) => ({
      ...question,
      noneAnswer: {
        id: nanoid(),
        isAnchored: true,
        isExclusive: true,
        isNoneOfTheAbove: true,
        text: noneOfTheAbove,
      },
    }));
  };

  const addOtherAnswer = () => {
    setQuestion((question) => ({
      ...question,
      otherAnswer: {
        id: nanoid(),
        isAnchored: true,
        isOther: true,
        text: otherPleaseSpecify,
      },
    }));
  };

  const deleteAnswer = (index) => {
    const answer = question.answers[index];
    if (typeof answer.id === 'number') {
      deletedAnswers.push({ ...answer });
    }

    question.answers.splice(index, 1);
    setQuestion({ ...question, answers: [...question.answers] });
  };

  const disableSaveButton = () => {
    // pasting text into <MentionsInput /> can exceed its maxLength, these checks are still necessary
    // https://github.com/signavio/react-mentions/issues/616
    if (!question.text.trim() || question.text.length > QUESTION_MAX_LENGTH) {
      return true;
    }

    if (
      [
        QUESTION_TYPES.RADIO.value,
        QUESTION_TYPES.CHECKBOX.value,
        QUESTION_TYPES['RANK-DRAGDROP'].value,
      ].includes(question.type.value)
    ) {
      if (
        question.answers.length === 0 ||
        question.answers.some(({ text }) => !text.trim() || text.length > ANSWER_MAX_LENGTH)
      )
        return true;
    }

    if (isRankType) {
      if (question.answers.length > QUESTION_RANK_MAX_OPTIONS) return true;
      if (question.noneAnswer || question.otherAnswer) return true;
    }

    return false;
  };

  const getAnswerInputError = ({ answer, index }) => {
    if (isRankType && index >= QUESTION_RANK_MAX_OPTIONS)
      return `Rank questions are limited to ${QUESTION_RANK_MAX_OPTIONS} options.  If you need more, consider adding another question.`;
    if (answer.text?.length > ANSWER_MAX_LENGTH) return ANSWER_LENGTH_ERROR;
    if (!answer.text?.trim() && answer.cdnUrl) return 'Question options require text';
    return null;
  };

  const onAnswerDragEnd = (result) => {
    if (!result?.destination) {
      return;
    }
    const answers = [...question.answers];
    const [removed] = answers.splice(result?.source?.index, 1);
    answers.splice(result?.destination?.index, 0, removed);
    setQuestion({ ...question, answers });
  };

  const onAnswerPaste = (event, value, index) => {
    const values = value
      .split(/[\n\r]+/) // split on newlines and returns
      .filter((n) => n) // filter removes empty string in cases of repeated line breaks
      .map((text) => text.trim().replace(/^[\s•*\-□]+/, '')) // trim whitespace and remove bullet points, checkboxes, and dashes
      .filter((text) => {
        if (otherAlternates.includes(text.toLowerCase())) {
          if (!question.otherAnswer) addOtherAnswer();
          return false;
        } else if (text.toLowerCase() === noneOfTheAbove.toLowerCase()) {
          if (!question.noneAnswer) addNoneAnswer();
          return false;
        }
        return true;
      })
      .map((text) => ({
        id: nanoid(),
        text,
      }));

    if (values.length <= 1) {
      return;
    }
    event.preventDefault();

    const deletedItems = question.answers.splice(index, values.length, ...values);
    setDeletedAnswers([
      ...deletedAnswers,
      ...deletedItems.filter(({ id }) => typeof id === 'number'),
    ]);
    setQuestion((question) => ({ ...question })); // updates the answers prop after the above splice()
  };

  const onImageUpload = async (file, index) => {
    setImageError(false, index);
    setImageLoading(true, index);

    try {
      const imageObject = await createImageObject({ objectType: file.type.substring(6) });
      if (!imageObject.url.startsWith('http://s3mock')) {
        await uploadImageToS3({ file, url: imageObject.url });
      }
      setUploadedImage(imageObject, index);
      setImageLoading(false, index);
    } catch (error) {
      setImageError(true, index);
      setImageLoading(false, index);
      throw error; // passes error back to RichTextInput handler
    }
  };

  const onSave = async () => {
    setErrorSubmitting(null);
    setIsSubmitting(true);

    const { image, noneAnswer, otherAnswer, randomizeOptions, text, type } = question;
    let answers = [...question.answers];

    if (otherAnswer) answers.push(otherAnswer);
    if (noneAnswer) answers.push(noneAnswer);

    if (type.value === QUESTION_TYPES.ESSAY.value) {
      // mark any existing answers as _delete: true
      answers = answers
        .filter(({ id }) => typeof id === 'number')
        .map((answer) => ({ _delete: true, ...answer }));
    }

    const controller = new AbortController();
    try {
      let data;
      if (question.id) {
        data = await updateQuestion({
          question: {
            answers: [
              ...answers,
              ...deletedAnswers.map((answer) => ({ _delete: true, ...answer })),
            ],
            id: question.id,
            image,
            randomizeOptions,
            text,
            type: type.value,
          },
          signal: controller.signal,
        });
      } else {
        data = await createQuestion({
          question: {
            answers,
            folder: FOLDER_ID_CUSTOM,
            image,
            instantSurveyTypes: [surveyType.id],
            randomizeOptions,
            text,
            type: type.value,
          },
          signal: controller.signal,
        });
      }
      if (isEditing) return onUpdate(data);
      return onCreate(data);
    } catch (error) {
      if (!controller.signal.aborted) {
        setErrorSubmitting(error);
        setIsSubmitting(false);
      }
    }
  };

  const setImageError = (value, index) => {
    const updatedImageError = { ...hasImageError };
    if (index !== undefined) {
      updatedImageError.answers[index] = value;
    } else {
      updatedImageError.question = value;
    }
    setHasImageError(updatedImageError);
  };

  const setImageLoading = (value, index) => {
    const updatedImageLoading = { ...isImageLoading };
    if (index !== undefined) {
      updatedImageLoading.answers[index] = value;
    } else {
      updatedImageLoading.question = value;
    }
    setIsImageLoading(updatedImageLoading);
  };

  const setUploadedImage = (value, index) => {
    if (index !== undefined) {
      const answerId = question.answers[index].id;
      updateAnswer(answerId, { image: value });
    } else {
      setQuestion({ ...question, image: value });
    }
  };

  const updateAnswer = (answerId, changes) => {
    const answer = question.answers.find(({ id }) => answerId === id);
    Object.assign(answer, changes);
    setQuestion({ ...question, answers: question.answers });
  };

  const actionButtons = [
    <Button
      data-testid="modal-cancel-button"
      key="modal-cancel-button"
      onClick={onClose}
      text="Cancel"
      variant="secondary"
    />,
    <Button
      data-testid="modal-confirm-button"
      isDisabled={disableSaveButton()}
      isLoading={isSubmitting}
      key="modal-confirm-button"
      onClick={onSave}
      text={isEditing ? 'Update' : 'Create & Add'}
    />,
  ];
  const mentions = [
    {
      data: availableQuestionVariables.map((variable) => ({
        id: variable,
        display: variable,
      })),
      displayTransform: (_, display) => `[${display.toLowerCase()}]`,
      key: 'variables',
      markup: '[__display__]',
      trigger: '[',
    },
  ];
  const suggestions = {
    description: 'Use our dynamic variables to help with recalls for your surveys.',
    helpUrl: surveyType.helpUrl,
    title: 'Search for Variables',
  };

  return (
    <Modal
      buttons={actionButtons}
      isActive={true}
      onClose={onClose}
      size="large"
      title={`${isEditing ? 'Edit' : 'Create'} Survey Question`}
    >
      <div className={styles['modal-instant-survey-question']}>
        {errorSubmitting && (
          <Announcement
            text={
              errorSubmitting?.response?.data?.error ||
              `There was an error ${question.id ? 'updating' : 'creating'} your question.`
            }
            variant="error"
          />
        )}
        <div className={styles['modal-instant-survey-question-text-and-type']}>
          <RichTextInput
            autoFocus={question.id ? false : true}
            error={question.text.length > QUESTION_MAX_LENGTH ? QUESTION_LENGTH_ERROR : null}
            hasImageError={hasImageError.question}
            id="question-text-rich-input"
            imageUrl={question.image?.cdnUrl}
            isImageLoading={isImageLoading.question}
            label="Question"
            maxLength={QUESTION_MAX_LENGTH}
            mentions={mentions}
            onChange={(text) => setQuestion({ ...question, text })}
            onImageRemove={setUploadedImage}
            onImageUpload={onImageUpload}
            placeholder="Enter question"
            setHasImageError={setImageError}
            suggestions={suggestions}
            value={question.text}
          />
          <Select
            className={styles['modal-instant-survey-question-select']}
            error={errorSubmitting?.response?.data?.question_type}
            label="Type"
            onChange={(type) => setQuestion({ ...question, type })}
            options={availableQuestionTypes}
            value={question.type}
          />
        </div>

        {question.type.value === QUESTION_TYPES.ESSAY.value ? (
          <TextArea
            className={styles['modal-instant-survey-question-input']}
            label="Answer (Multiple Lines)"
            isDisabled
          />
        ) : (
          <>
            <Fieldset legend={`Answer Options (${ANSWER_MAX_LENGTH} characters)`}>
              <div className={styles['modal-instant-survey-question-randomize']}>
                <Checkbox
                  isChecked={question.randomizeOptions}
                  label="Randomize order"
                  onChange={() =>
                    setQuestion({ ...question, randomizeOptions: !question.randomizeOptions })
                  }
                  value={question.randomizeOptions}
                />
              </div>
              <DragDropContext onDragEnd={onAnswerDragEnd}>
                <Droppable droppableId="modalSurveyQuestionAnswers">
                  {(provided) => (
                    <ul ref={provided.innerRef} {...provided.droppableProps}>
                      {question.answers.map((answer, index) => {
                        return (
                          <Draggable
                            draggableId={`answer-${answer.id}`} // requires a string
                            key={answer.id}
                            index={index}
                            isDragDisabled={
                              question.answers.length === 1 || question.randomizeOptions
                            }
                          >
                            {(provided) => {
                              return (
                                <li
                                  className={styles['modal-instant-survey-question-answers-li']}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  tabIndex="-1"
                                >
                                  <Answer
                                    answer={answer}
                                    isDraggable
                                    onAnchor={() =>
                                      updateAnswer(answer.id, {
                                        isAnchored: !answer.isAnchored,
                                      })
                                    }
                                    onDelete={() => deleteAnswer(index)}
                                    onMakeExclusive={() =>
                                      updateAnswer(answer.id, {
                                        isExclusive: !answer.isExclusive,
                                      })
                                    }
                                    type={question.type.value}
                                  >
                                    <RichTextInput
                                      error={getAnswerInputError({ answer, index })}
                                      hasImageError={hasImageError.answers[index]}
                                      imageUrl={answer.image?.cdnUrl}
                                      isImageLoading={isImageLoading.answers[index]}
                                      maxLength={ANSWER_MAX_LENGTH}
                                      mentions={mentions}
                                      onChange={(value) => updateAnswer(answer.id, { text: value })}
                                      onImageRemove={() => setUploadedImage(null, index)}
                                      onImageUpload={(file) => onImageUpload(file, index)}
                                      onPaste={(event, value) => onAnswerPaste(event, value, index)}
                                      placeholder="Enter answer"
                                      setHasImageError={(value) => setImageError(value, index)}
                                      suggestions={suggestions}
                                      value={answer.text}
                                    />
                                  </Answer>
                                </li>
                              );
                            }}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>

              {(question.otherAnswer || question.noneAnswer) && (
                <ul className={styles['modal-instant-survey-question-options']}>
                  {question.otherAnswer && (
                    <li className={styles['modal-instant-survey-question-answers-li']}>
                      <Answer
                        answer={question.otherAnswer}
                        onDelete={() => {
                          if (typeof question.otherAnswer.id === 'number') {
                            setDeletedAnswers([...deletedAnswers, { ...question.otherAnswer }]);
                          }
                          setQuestion({ ...question, otherAnswer: null });
                        }}
                        onMakeExclusive={() => {
                          setQuestion({
                            ...question,
                            otherAnswer: {
                              ...question.otherAnswer,
                              isExclusive: !question.otherAnswer.isExclusive,
                            },
                          });
                        }}
                        type={question.type.value}
                      >
                        <div className={styles['modal-instant-survey-question-other']}>
                          <div>
                            {question.otherAnswer.text}:&nbsp;
                            <em>Panelist's write-in</em>
                          </div>
                          {isRankType && (
                            <ErrorText>Can't have a write-in with ranked question type.</ErrorText>
                          )}
                        </div>
                      </Answer>
                    </li>
                  )}
                  {question.noneAnswer && (
                    <li className={styles['modal-instant-survey-question-answers-li']}>
                      <Answer
                        answer={question.noneAnswer}
                        onDelete={() => {
                          if (typeof question.noneAnswer.id === 'number') {
                            setDeletedAnswers([...deletedAnswers, { ...question.noneAnswer }]);
                          }
                          setQuestion({ ...question, noneAnswer: null });
                        }}
                        type={question.type.value}
                      >
                        <div className={styles['modal-instant-survey-question-none']}>
                          <div>{question.noneAnswer.text}</div>
                          {isRankType && (
                            <ErrorText>
                              Can't have none of the above with ranked question type.
                            </ErrorText>
                          )}
                        </div>
                      </Answer>
                    </li>
                  )}
                </ul>
              )}
            </Fieldset>

            <div className={styles['modal-instant-survey-question-actions']}>
              <PlusIcon />
              <ButtonText
                data-testid="add-answer-button"
                disabled={isRankType && question.answers.length >= QUESTION_RANK_MAX_OPTIONS}
                onClick={addAnswer}
              >
                <strong>New</strong>&nbsp;Option
                {canAddOther || canAddNone ? ',' : null}
              </ButtonText>
              {canAddOther && (
                <ButtonText data-testid="add-other-button" onClick={addOtherAnswer}>
                  <strong>"Other, Please Specify"</strong>&nbsp;Option
                  {!question.noneAnswer ? ',' : null}
                </ButtonText>
              )}
              {canAddNone && (
                <ButtonText data-testid="add-none-button" onClick={addNoneAnswer}>
                  <strong>"None of the above"</strong>&nbsp;Option
                </ButtonText>
              )}
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

ModalInstantSurveyQuestion.defaultProps = {
  isDuplicate: false,
};

ModalInstantSurveyQuestion.propTypes = {
  isDuplicate: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  originalQuestion: PropTypes.object,
  surveyType: PropTypes.object.isRequired,
};

export default ModalInstantSurveyQuestion;
