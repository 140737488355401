export const availableQuestionVariables = [
  'banner',
  'brand',
  'category',
  'channel',
  'date',
  'majorcat',
  'parentbrand',
  'parentchannel',
  'retailer',
  'subcategory',
];
export const noneOfTheAbove = 'None of the above';
export const otherPleaseSpecify = 'Other, please specify';
export const otherAlternates = [
  otherPleaseSpecify.toLowerCase(),
  'other: please specify',
  'other please specify',
  'other (specify)',
];
