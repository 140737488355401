/**
 * Categorizes routes into standard surveys, specialty surveys, favorites, and all routes.
 * @param {Array} routes
 * @param {boolean} showFavorites
 * @returns {Object} Categorized routes
 */
export const categorizeRoutes = (routes, showFavorites) => {
  const favoriteRoutes = routes.filter((route) => route.is_favorite);
  const standardSurveyRoutes = routes.filter((route) => !route.isSpecialty);
  const specialtySurveyRoutes = routes.filter((route) => route.isSpecialty);
  const allRoutes = showFavorites ? routes.filter((route) => !route.is_favorite) : routes;

  return { allRoutes, favoriteRoutes, specialtySurveyRoutes, standardSurveyRoutes };
};

/**
 * Filters routes based on favorites preference.
 * @param {Array} routes
 * @param {boolean} showFavorites
 * @returns {Object} Filtered routes including favorites and all other routes.
 */
export const filterRoutes = (routes, showFavorites) => ({
  allRoutes: showFavorites ? routes.filter((route) => !route.is_favorite) : routes,
  favoriteRoutes: routes.filter((route) => route.is_favorite),
});
