import { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { QUESTION_LOGIC_OPERATORS, QUESTION_TYPES } from '@api/instant_survey';
import { getQuestionIcon } from '@helpers';

import {
  ActionOverlayPopover,
  CopyIcon,
  DragDropV2Icon,
  FlowArrowIcon,
  Layout,
  PencilIcon,
  Pill,
  TargetIcon,
  TrashIcon,
} from '@utilities';

import QuestionLogic from '../../../QuestionLogic';

import styles from './_index.module.scss';

const answerOperators = [QUESTION_LOGIC_OPERATORS.ANY, QUESTION_LOGIC_OPERATORS.NOT];
const skipOperators = [QUESTION_LOGIC_OPERATORS.ALL, QUESTION_LOGIC_OPERATORS.ANY];

const Question = ({
  firstErrorRef,
  index,
  isDetailedQuestionView,
  maxQuestions,
  number,
  onDuplicateQuestion,
  onEditQuestion,
  onRemoveQuestion,
  provided,
  question,
  questionLogic,
  selectedQuestions,
  setQuestionLogic,
}) => {
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const optionsDropdown = useRef(null);

  const addDisqualifyLogic = () => {
    const updatedLogic = { ...questionLogic };
    updatedLogic[question.id] = {
      ...updatedLogic[question.id],
      disqualify: {
        answers: [],
        operator: answerOperators[0],
      },
    };
    setQuestionLogic(updatedLogic);
  };

  const addSkipLogic = () => {
    const updatedLogic = { ...questionLogic };
    updatedLogic[question.id] = {
      ...updatedLogic[question.id],
      skip: {
        operator: skipOperators[0],
        logic: [
          {
            answers: [],
            operator: answerOperators[0],
            question: {},
          },
        ],
      },
    };
    setQuestionLogic(updatedLogic);
  };

  const canAddDisqualifyLogic =
    index < 2 &&
    ![QUESTION_TYPES.ESSAY.value, QUESTION_TYPES['RANK-DRAGDROP'].value].includes(question.type);
  const canAddSkipLogic = index > 0;

  const removeQuestion = () => {
    onRemoveQuestion(question);
    setIsDropdownActive(false);
  };

  return (
    <div
      className={styles['question-draggable-container']}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      {questionLogic[question.id]?.skip && (
        <QuestionLogic
          firstErrorRef={firstErrorRef}
          isDetailedQuestionView={isDetailedQuestionView}
          question={question}
          questionIndex={index}
          questionLogic={questionLogic}
          selectedQuestions={selectedQuestions}
          setQuestionLogic={setQuestionLogic}
          type="skip"
        />
      )}

      <Layout.Flex className={styles['question-card']}>
        <div>
          <div className={styles['question-drag-handle']}>
            <DragDropV2Icon />
          </div>
          <div className={styles['question-number']}>{number}</div>
        </div>

        <Layout.Flex.Column className={styles['question-body']}>
          <Layout.Flex gap="small">
            <div className={styles['question-text']}>{question.text}</div>
            <div className={styles['question-pill-wrapper']}>
              <Pill
                className={styles['question-type']}
                icon={getQuestionIcon(question)}
                isCompleted
                value={QUESTION_TYPES[question.type]?.name}
              />
            </div>
          </Layout.Flex>
          {question.image?.cdnUrl && (
            <img
              alt="Uploaded File"
              className={styles['question-image']}
              src={question.image.cdnUrl}
            />
          )}
          {isDetailedQuestionView && (
            <div className={styles['question-detailed-view']}>
              {question.type === QUESTION_TYPES.ESSAY.value ? (
                <span className={styles['question-open-ended']}>Panelist's write-in</span>
              ) : (
                <>
                  {question.answers.map((answer) => (
                    <Layout.Flex
                      className={styles['question-answer']}
                      gap="small"
                      key={`answer-${answer.id}`}
                    >
                      {getQuestionIcon(question)}
                      <div className={styles['question-answer-body']} gap="x-small">
                        {answer.text && <span>{answer.text}</span>}
                        {answer.image?.cdnUrl && (
                          <img
                            alt="Uploaded File"
                            className={styles['question-answer-image']}
                            src={answer.image.cdnUrl}
                          />
                        )}
                      </div>
                    </Layout.Flex>
                  ))}
                </>
              )}
            </div>
          )}
        </Layout.Flex.Column>

        <div className={styles['question-actions']}>
          <ActionOverlayPopover
            hasArrow={false}
            isActive={isDropdownActive}
            onClick={() => setIsDropdownActive(!isDropdownActive)}
            onHide={() => setIsDropdownActive(false)}
            target={optionsDropdown.current}
          >
            <ul>
              <li>
                <button
                  aria-label="Edit question"
                  data-testid="edit-question-button"
                  onClick={() => {
                    onEditQuestion(question);
                    setIsDropdownActive(false);
                  }}
                >
                  <PencilIcon />
                  Edit Question
                </button>
              </li>
              <li>
                <button
                  aria-label="Duplicate question"
                  data-testid="duplicate-question-button"
                  disabled={selectedQuestions.length >= maxQuestions}
                  onClick={() => {
                    onDuplicateQuestion(question);
                    setIsDropdownActive(false);
                  }}
                >
                  <CopyIcon />
                  Duplicate Question
                </button>
              </li>
              {canAddSkipLogic && (
                <li>
                  <button
                    aria-label="Ask this question if"
                    data-testid="ask-this-question-if-button"
                    disabled={questionLogic[question.id]?.skip}
                    onClick={() => {
                      addSkipLogic();
                      setIsDropdownActive(false);
                    }}
                  >
                    <FlowArrowIcon />
                    Ask This Question If
                  </button>
                </li>
              )}
              {canAddDisqualifyLogic && (
                <li>
                  <button
                    aria-label="Disqualify panelists"
                    data-testid="disqualify-panelists-button"
                    disabled={questionLogic[question.id]?.disqualify}
                    onClick={() => {
                      addDisqualifyLogic();
                      setIsDropdownActive(false);
                    }}
                  >
                    <TargetIcon />
                    Disqualify Panelists
                  </button>
                </li>
              )}
              <hr />
              <li>
                <button
                  aria-label="Remove from Survey"
                  data-testid="remove-question-button"
                  onClick={removeQuestion}
                >
                  <TrashIcon />
                  <span>Remove from Survey</span>
                </button>
              </li>
            </ul>
          </ActionOverlayPopover>
        </div>
      </Layout.Flex>

      {questionLogic[question.id]?.disqualify && (
        <QuestionLogic
          firstErrorRef={firstErrorRef}
          isDetailedQuestionView={isDetailedQuestionView}
          question={question}
          questionLogic={questionLogic}
          setQuestionLogic={setQuestionLogic}
          type="disqualify"
        />
      )}
    </div>
  );
};

Question.propTypes = {
  firstErrorRef: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  isDetailedQuestionView: PropTypes.bool.isRequired,
  maxQuestions: PropTypes.number.isRequired,
  number: PropTypes.number.isRequired,
  onDuplicateQuestion: PropTypes.func,
  onEditQuestion: PropTypes.func,
  onRemoveQuestion: PropTypes.func,
  provided: PropTypes.object,
  question: PropTypes.object.isRequired,
  questionLogic: PropTypes.object.isRequired,
  selectedQuestions: PropTypes.array.isRequired,
  setQuestionLogic: PropTypes.func.isRequired,
};

export default Question;
